import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./Department.module.css";
import Doctor_1 from "../../assets/images/doctor1.png";
import DepartmentImage_1 from "../../assets/images/departmentimage1.png";
import HoverImage from "../../components/HoverImage/HoverImage";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
const imageBaseUrl = "/departments";
const doctorsImagePath = "/doctors";

function Department() {
  let { department: initialDepartment } = useParams();
  const [department, setDepartment] = useState(initialDepartment);
  const navigate = useNavigate();
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentDropDownData, setDepartmentDropDownData] = useState([]);
  const [doctorData, setDoctorData] = useState([]);

  console.log(departmentData);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleChangeDepartment = (event) => {
    const selectedValue = event.target.value;
    setDepartment(selectedValue);
    // Redirect to the selected department page
    navigate(`/departments/${selectedValue}`);
  };

  const handleDoctorProfileClick = (name) => {
    navigate(`/doctor/${name}`);
  };

  // const deptData = Departments.find((dept) => dept.department === department);

  useEffect(() => {
    // Fetches the data whenever 'department' changes
    const fetchSearchDepartmentApi = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/departments/search?Department=${department}`
        );
        setDepartmentData(response?.data?.data);
        const response2 = await axios.get(
          `${apiBaseUrl}/api/doctors/search?Department_ID=${response?.data?.data[0]?.SL_NO}`
        );
        setDoctorData(response2?.data?.data);
      } catch (error) {
        console.error("Error while fetching data", error);
      }
    };

    const fetchDepartmentDataApi = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/api/departments/getAll`
        );
        setDepartmentDropDownData(response?.data?.data);
      } catch (error) {
        console.error("Error while fetching data", error);
      }
    };

    fetchSearchDepartmentApi();
    fetchDepartmentDataApi();
  }, [department]);

  if (!departmentData) {
    // Handle the case when 'name' doesn't match any data
    return <div style={{ marginTop: "100px" }}>Department not found</div>;
  }

  const servicesData = departmentData[0]?.services;

  // Split the data into an array using the newline character '\n'
  const servicesArray = servicesData?.split("\n").filter(Boolean);

  const facilitiesData = departmentData[0]?.facilities;

  // Split the data into an array using the newline character '\n'
  const facilitiesArray = facilitiesData?.split("\n").filter(Boolean);

  return (
    <Container
      component="main"
      maxWidth={false}
      style={{
        width: "90%",
        display: "flex",
        flexDirection: "column",
        marginTop: "100px",
      }}
      className={styles.DepartmentContainer}>
      <Grid container columnGap={12}>
        <Grid item xs={12} md={4} marginTop={"30px"}>
          <Typography fontSize={"35px"} fontFamily={"Inter"} fontWeight={"400"}>
            {departmentData[0]?.Department}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} marginTop={"30px"}>
          <FormControl
            fullWidth
            sx={{
              height: "82px",
              "& .MuiSelect-root": { backgroundColor: "transparent" },
            }}>
            <InputLabel>Department</InputLabel>
            <Select
              value={department}
              label="Department"
              onChange={handleChangeDepartment}>
              {departmentDropDownData.map(({ Department }, index) => (
                <MenuItem value={Department}>{Department}</MenuItem>
              ))}

              {/* <MenuItem value="General Medicine">General Medicine</MenuItem> */}
              {/* <MenuItem value="Dentals">Dentals</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container columnGap={12} marginTop={"30px"}>
        <Grid item xs={12} sm={12} md={5} lg={3}>
          <Grid container>
            <Grid item xs={12}>
              <img
                width={"100%"}
                src={`${imageBaseUrl}/${departmentData[0]?.SL_NO}.jpg`}
                onError={(e) => (e.target.src = DepartmentImage_1)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                textAlign={"justify"}
                marginTop={"30px"}>
                {departmentData[0]?.para1}
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={8}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                marginBottom={"30px"}
                textAlign={"justify"}
                marginTop={"30px"}>
                {departmentData[0]?.para1}
                {/* {deptData.para2} */}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                color="#211E1E"
                fontSize="18px"
                fontFamily="Inter"
                fontWeight="400"
                lineHeight="1.9 "
                letterSpacing="0.36"
                wordWrap="break-word"
                textAlign={"justify"}>
                {/* {deptData.para3} */}
                {departmentData[0]?.para3}
              </Typography>
            </Grid>
            {/* <Grid container spacing={6} marginTop={"5px"}>
              {deptData.bullets.map((bullet) => (
                <Grid item xs={12} sm={6} md={6} key={bullet.id}>
                  <Typography
                    color="#211E1E"
                    fontSize="24px"
                    fontFamily="Inter"
                    fontWeight="600"
                    lineHeight="1.9 "
                    letterSpacing="0.36"
                  >
                    {bullet.title}
                  </Typography>
                  <Typography
                    color="#211E1E"
                    fontSize="16px"
                    fontFamily="Inter"
                    fontWeight="400"
                    lineHeight="1.9 "
                    letterSpacing="0.36"
                    wordWrap="break-word"
                  >
                    {bullet.desc}
                  </Typography>
                  <List>
                    {bullet.bullets.map((subBullet) => (
                      <ListItem sx={{ padding: 0 }} key={subBullet.id}>
                        <ListItemIcon sx={{ minWidth: "32px" }}>
                          <CheckIcon
                            style={{
                              fontSize: "18px",
                              marginRight: "8px",
                              color: "#4FB92A",
                            }}
                          />
                        </ListItemIcon>
                        <Typography
                          color="#211E1E"
                          fontSize="18px"
                          fontFamily="Inter"
                          fontWeight="400"
                          lineHeight="2.7"
                          letterSpacing="0.36"
                          wordWrap="break-word"
                        >
                          {subBullet.name}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              ))}
            </Grid> */}
          </Grid>
          <Grid container marginTop={"30px"}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="24px"
                fontFamily="Inter"
                fontWeight="600">
                Services
              </Typography>
              <ul>
                {servicesArray?.map((service, index) => {
                  const [mainService, subContents] = service.split("||"); // Main service section

                  // Handle sublist items (split by "|")
                  const subContentsArray = subContents
                    ? subContents.split("|").map((sub) => sub.trim())
                    : [];

                  return (
                    <li key={index}>
                      <Typography
                        color="#211E1E"
                        fontSize="18px"
                        fontFamily="Inter"
                        fontWeight="400">
                        {mainService} {/* This is the title of the service */}
                      </Typography>

                      {/* Iterate through sublist items */}
                      {subContentsArray.length > 0 && (
                        <ul style={{ paddingLeft: "20px" }}>
                          {subContentsArray.map((subContent, subIndex) => {
                            // Check if the subContent has a nested list using "~" separator
                            const subParts = subContent.split("~"); // Split by "~" for nested items

                            return (
                              <li key={`${index}-${subIndex}`}>
                                <Typography
                                  color="#211E1E"
                                  fontSize="16px"
                                  fontFamily="Inter"
                                  fontWeight="400">
                                  {subParts[0]}{" "}
                                  {/* This is the main subservice */}
                                </Typography>

                                {/* If there are nested subitems (after split by "~") */}
                                {subParts.length > 1 && (
                                  <ul style={{ paddingLeft: "20px" }}>
                                    {subParts
                                      .slice(1)
                                      .map((nestedContent, nestedIndex) => (
                                        <li
                                          key={`${index}-${subIndex}-${nestedIndex}`}>
                                          <Typography
                                            color="#211E1E"
                                            fontSize="14px"
                                            fontFamily="Inter"
                                            fontWeight="400">
                                            {nestedContent}{" "}
                                            {/* This is the nested subservice */}
                                          </Typography>
                                        </li>
                                      ))}
                                  </ul>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Typography
                color="#211E1E"
                fontSize="24px"
                fontFamily="Inter"
                fontWeight="600">
                Facilities
              </Typography>
              <ul>
                {facilitiesArray?.map((facility, index) => (
                  <li key={index}>
                    <Typography
                      color="#211E1E"
                      fontSize="18px"
                      fontFamily="Inter"
                      fontWeight="400">
                      {facility}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography
            marginTop="110px"
            fontFamily="Inter"
            fontSize="35px"
            fontWeight="700"
            letterSpacing="0.7px"
            lineHeight="133.523%"
            textAlign="center">
            Meet Our Team
          </Typography>
          <Grid
            container
            spacing={5}
            display="flex"
            justifyContent="center"
            sx={{ marginTop: "10px", marginBottom: "36px" }}>
            {doctorData.map(({ GG_ID, Name }, index) => (
              <Grid item xs={12} sm={6} md={6} lg={3} key={GG_ID}>
                <Grid container>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <HoverImage
                      image={`${doctorsImagePath}/${GG_ID}.jpg`}
                      width={"320px"}
                      height={"350px"}
                      btnName={"View Profile"}
                      objectFit="scale-down"
                      onButtonClick={() => handleDoctorProfileClick(Name)}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "20px" }}>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="23px"
                      fontStyle="normal"
                      fontWeight="560"
                      letterSpacing="0.6px"
                      lineHeight="133.523%"
                      textAlign="center">
                      {Name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: "" }}>
                    <Typography
                      color="var(--dark, #211E1E)"
                      fontFamily="Inter"
                      fontSize="14px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="133.523%"
                      textAlign="center"
                      sx={{ opacity: "0.5" }}>
                      {department}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Department;
